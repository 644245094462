const reservationConfirmTypes = {
  "TYPES" : [
    {
      Value : 'NEW',
      Title : 'Yeni',
      BadgeName: 'primary',
    },
    {
      Value : 'WAITING',
      Title : 'Cevap Bekleniyor',
      Title2 : '(Yeni Rez.)',
      BadgeName: 'warning',
    },
    {
      Value : 'NOT CONFIRM',
      Title : 'Otel Red',
      BadgeName: 'danger',
    },
    {
      Value : 'CONFIRM',
      Title : 'Onaylı',
      BadgeName: 'success',
    },
    {
      Value : 'NEW UPDATE',
      Title : 'Güncelle',
      BadgeName: 'primary',
    },
    {
      Value : 'WAITING UPDATE',
      Title : 'Güncelleme Bekleniyor',
      BadgeName: 'warning',
    },
    {
      Value : 'NEW CANCEL',
      Title : 'İptal',
      BadgeName: 'secondary',
    },
    {
      Value : 'WAITING CANCEL',
      Title : 'İptal Bekleniyor',
      BadgeName: 'warning',
    },
  ],
};
export default reservationConfirmTypes;
