
import {computed, defineComponent, ref} from "vue";
import {ErrorMessage, Field, Form} from "vee-validate";
import {hideModal} from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import store from "@/store";
import Tagify from '@yaireo/tagify';
import {Actions} from "@/store/enums/StoreEnums";
import roomPriceType from "@/core/data/roompricetype";
import roomPricePlatform from "@/core/data/roompriceplatform";
import flatpickr from "flatpickr";
import 'flatpickr/dist/flatpickr.css';
import {Turkish} from "flatpickr/dist/l10n/tr"
import weekDaysList from "@/core/data/weekdays";
import roomMinAccType from "@/core/data/roomminacctype";
import roomProfitType from "@/core/data/roomprofittype";
import curList from "@/core/data/currency";

import MasterConfig from "@/core/config/MasterConfig";
import curExchangeType from "@/core/data/curexchangetype";
import {commonf} from "@/core/filters/common";


interface newPriceData {
  roomTitle: string;
  roomCategory: string;
  roomADT: string;
  roomCHD: string;
  roomSale: string;
  roomActive: string;

  childAgeStart: Array<any>;
  childAgeFinish: Array<any>;
  childAgeFrom: Array<any>;
  childAgeTo: Array<any>;
  childMinADT: Array<any>;

  childPrice: Array<any>;
  childCost: Array<any>;
  adultPrice: Array<any>;
  adultCost: Array<any>;


  childAgeStartMarket: Array<any>;
  childAgeFinishMarket: Array<any>;
  childAgeFromMarket: Array<any>;
  childAgeToMarket: Array<any>;
  childMinADTMarket: Array<any>;

  childPriceMarket: Array<any>;
  childCostMarket: Array<any>;
  adultPriceMarket: Array<any>;
  adultCostMarket: Array<any>;

  childAgeStartAccount: Array<any>;
  childAgeFinishAccount: Array<any>;
  childAgeFromAccount: Array<any>;
  childAgeToAccount: Array<any>;
  childMinADTAccount: Array<any>;

  childPriceAccount: Array<any>;
  childCostAccount: Array<any>;
  adultPriceAccount: Array<any>;
  adultCostAccount: Array<any>;

  myTest: Array<any>;

  chkValidDay: Array<any>;
  chkCheckInDay: Array<any>;


  dateValidStart: string;
  dateValidFinish: string;
  dateReservationStart: string;
  dateReservationFinish: string;

  minAccType: Array<any>;
  minAccStatus: Array<any>;
  minAccDay: Array<any>;
  minPayment: Array<any>;

  minAccTypeAccount: Array<any>;
  minAccStatusAccount: Array<any>;
  minAccDayAccount: Array<any>;
  minPaymentAccount: Array<any>;

  minAccTypeMarket: Array<any>;
  minAccStatusMarket: Array<any>;
  minAccDayMarket: Array<any>;
  minPaymentMarket: Array<any>;

  priceOverComm: Array<any>;
  cancelPolicy: Array<any>;
  priceProfitType: Array<any>;
  priceProfit: Array<any>;

  priceOverCommAccount: Array<any>;
  cancelPolicyAccount: Array<any>;
  priceProfitTypeAccount: Array<any>;
  priceProfitAccount: Array<any>;

  priceOverCommMarket: Array<any>;
  cancelPolicyMarket: Array<any>;
  priceProfitTypeMarket: Array<any>;
  priceProfitMarket: Array<any>;


  priceCur: Array<any>;
  costCur: Array<any>;
  curRate: Array<any>;
  curRateType: Array<any>;

  priceCurAccount: Array<any>;
  costCurAccount: Array<any>;
  curRateAccount: Array<any>;
  curRateTypeAccount: Array<any>;

  priceCurMarket: Array<any>;
  costCurMarket: Array<any>;
  curRateMarket: Array<any>;
  curRateTypeMarket: Array<any>;

  priceHotel: string;
  priceHotelTitle: string;
  priceRoom: string;
  priceRoomTitle: string;
  priceConcept: string;
  priceConceptTitle: string;
  priceMarkets: Array<any>;
  priceAccount: Array<any>;
  priceType: string;
  pricePlatform: string;

  priceSaleCur: string;

  priceADT: string;
  priceCHD: string;


}

export default defineComponent({
  name: "new-room-modal",
  components: {
    ErrorMessage,
    Field,
    Form,
  },
  data() {
    return {
      startpicker: null,
      endpicker: null,
      prsStartpicker: null,
      prsEndpicker: null,
    }
  },
  props: {
    hotelID: String,
  },
  mounted() {
    this.datapicker();
    return true;
  },
  methods: {
    datapicker() {
      let config = {
        "locale": Turkish,
        dateFormat: "d-m-Y (D)",
        minDate: "today",
      };
      this.startpicker = flatpickr("#kt_datepicker_1", config);
      this.endpicker = flatpickr("#kt_datepicker_2", config);
      this.prsStartpicker = flatpickr("#kt_datepicker_3", config);
      this.prsEndpicker = flatpickr("#kt_datepicker_4", config);
    },
  },
  setup(props) {
    const submitButtonRef = ref<null | HTMLButtonElement>(null);
    const newRoomModalRef = ref<null | HTMLElement>(null);
    const roomSale = ref(null)
    const roomActive = ref(null)

    const payloadHD = {
      ID: props.hotelID,
    }
    //store.dispatch(Actions.HOTEL_DETAILS, payloadHD);
    const myDetails2 = computed(() => {
      return store.getters.hotelDetails;
    });


    const payload = {
      HotelID: props.hotelID,
      is_active: "active",
    }

    store.dispatch(Actions.HOTEL_ROOMS_LIST, payload);
    const myRoomsList = computed(() => {
      return store.getters.hotelRoomsList;
    });
    const payload2 = {
      HotelID: props.hotelID,
      is_active: "active",
    }
    store.dispatch(Actions.HOTEL_CONCEPTS_LIST, payload2);
    const myConceptList = computed(() => {
      return store.getters.hotelConceptsList;
    });


    const newPriceData = ref<newPriceData>({
      roomTitle: "",
      roomCategory: "",
      roomADT: "",
      roomCHD: "",
      roomSale: "",
      roomActive: "",

      childAgeStart: [],
      childAgeFinish: [],
      childAgeFrom: [],
      childAgeTo: [],

      childPrice: [],
      childCost: [],
      childMinADT: [],

      myTest: [],
      adultPrice: [],
      adultCost: [],

      childAgeStartMarket: [],
      childAgeFinishMarket: [],
      childAgeFromMarket: [],
      childAgeToMarket: [],

      childPriceMarket: [],
      childCostMarket: [],
      childMinADTMarket: [],

      adultPriceMarket: [],
      adultCostMarket: [],

      childAgeStartAccount: [],
      childAgeFinishAccount: [],
      childAgeFromAccount: [],
      childAgeToAccount: [],

      childPriceAccount: [],
      childCostAccount: [],
      childMinADTAccount: [],

      adultPriceAccount: [],
      adultCostAccount: [],

      dateValidStart: "",
      dateValidFinish: "",
      dateReservationStart: "",
      dateReservationFinish: "",

      chkValidDay: [true, true, true, true, true, true, true],
      chkCheckInDay: [true, true, true, true, true, true, true],

      minAccType: [],
      minAccStatus: [],
      minAccDay: [],
      minPayment: [],

      minAccTypeAccount: [],
      minAccStatusAccount: [],
      minAccDayAccount: [],
      minPaymentAccount: [],

      minAccTypeMarket: [],
      minAccStatusMarket: [],
      minAccDayMarket: [],
      minPaymentMarket: [],

      cancelPolicy: [],
      priceProfitType: [],
      priceProfit: [],
      priceOverComm: [],

      cancelPolicyAccount: [],
      priceProfitTypeAccount: [],
      priceProfitAccount: [],
      priceOverCommAccount: [],

      cancelPolicyMarket: [],
      priceProfitTypeMarket: [],
      priceProfitMarket: [],
      priceOverCommMarket: [],


      priceCur: [],
      costCur: [],
      curRate: [],
      curRateType: [],

      priceCurAccount: [],
      costCurAccount: [],
      curRateAccount: [],
      curRateTypeAccount: [],

      priceCurMarket: [],
      costCurMarket: [],
      curRateMarket: [],
      curRateTypeMarket: [],


      priceHotel: myDetails2.value.ID,
      priceHotelTitle: myDetails2.value.Title,
      priceRoom: "",
      priceRoomTitle: "",
      priceConcept: "",
      priceConceptTitle: "",
      priceMarkets: [],
      priceAccount: [],
      priceType: "PAX",
      pricePlatform: "",
      priceSaleCur: "",
      priceADT: "",
      priceCHD: "",
    });

    const validationSchema = Yup.object().shape({
      roomTitle: Yup.string().required().label("Oda İsmi"),
      roomCategory: Yup.string().required().label("Oda Kategory"),
      roomADT: Yup.string().required().label("Yetişkin Kapasite"),
    });
    const submit = () => {
      if (!submitButtonRef.value) {
        return;
      }
      //Disable button
      submitButtonRef.value.disabled = true;
      // Activate indicator
      submitButtonRef.value.setAttribute("data-kt-indicator", "on");

      let isActive = (roomActive.value.checked) ? 'active' : 'passive';
      let isSale = (roomSale.value.checked) ? 'open' : 'stop';

      const payload1 = {
        HotelID: myDetails2.value.ID,
        Title: newPriceData.value.roomTitle,
        TypeID: newPriceData.value.roomCategory,
        AdultMax: newPriceData.value.roomADT,
        ChildMax: newPriceData.value.roomCHD,
        PaxCombination: "",
        is_sale: isSale,
        is_active: isActive,
      }
      store.dispatch(Actions.HOTEL_ROOMS_INSERT, payload1)
          .then(() => {
            Swal.fire({
              text: "Tebrikler. Başarılı bir şekilde ekleme işlemini tamamladınız.",
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Geri Dön!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-primary",
              },
            }).then(function () {
              submitButtonRef.value.disabled = false;
              submitButtonRef.value.setAttribute("data-kt-indicator", "off");
              hideModal(newRoomModalRef.value);
              // Go to page after successfully login
              //router.push({name: "userlist"});
            });
          }).catch(() => {
        Swal.fire({
          text: store.getters.getUserErrors[0],
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Tekrar dene!",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        });
        submitButtonRef.value.disabled = false;
        submitButtonRef.value.setAttribute("data-kt-indicator", "off");
      });

    };


    let childAgeGroupCount = ref([]);
    const setCapacity = (e) => {
      newPriceData.value.priceADT = e.target.selectedOptions[0].dataset.adt;
      newPriceData.value.priceCHD = e.target.selectedOptions[0].dataset.chd;
      let start = 1;
      childAgeGroupCount.value = Array.from({length: (Number(newPriceData.value.priceCHD) + 1) - start}, (v, k) => (start));
    }
    const myAgeGroupCount = (id) => {
      return childAgeGroupCount.value[id];
    };

    const myAgeGroupCountPlus = (id) => {
      childAgeGroupCount.value[id]++;
    };

    const myAgeGroupCountMinus = (id) => {
      childAgeGroupCount.value[id]--;
    };


    store.dispatch(Actions.MARKETS_LIST_CACHE, {});
    const myMarkets = computed(() => {
      return store.getters.MarketsListCache;
    });
    const myTagsMarkets = computed(() => {
      if (myMarkets.value.data) {
        let tmpList = [];
        for (let i = 0; i < myMarkets.value.data.length; i++) {
          tmpList[i] = {
            value: (myMarkets.value.data[i].Title).trim() + "(" + myMarkets.value.data[i].Cur + ")",
            title: (myMarkets.value.data[i].Title).trim(),
            cur: myMarkets.value.data[i].Cur,
            type: 'market',
            ID: myMarkets.value.data[i].ID,
          };

        }
        setTagInput(tmpList)
        return tmpList;

      } else {
        return [];
      }
    });

    store.dispatch(Actions.ACCOUNT_LIST_ALL, {});
    const myAgency = computed(() => {
      return store.getters.accountList;
    });

    const myTagsAgency = computed(() => {
      if (myAgency.value.data) {
        let tmpList = [];
        for (let i = 0; i < myAgency.value.data.length; i++) {
          tmpList[i] = {
            value: (myAgency.value.data[i].Title).trim(),
            title: (myAgency.value.data[i].Title).trim(),
            type: 'account',
            ID: myAgency.value.data[i].ID,
          };

        }
        setTagInputAgency(tmpList);
        return tmpList;

      } else {
        return [];
      }
    });
    let tagify;
    let tagify2;
    const setTagInput = (wList) => {
      var input = document.querySelector('input[name=myTagsInput]');
// init Tagify script on the above inputs
      tagify = new Tagify(input, {
        tagTextProp: 'value', // very important since a custom template is used with this property as text
        enforceWhitelist: true,
        duplicates: false,
        skipInvalid: true, // do not remporarily add invalid tags
        whitelist: wList,
        dropdown: {
          searchKeys: ["value"],
          maxItems: 5,
          closeOnSelect: false,
          position: "text",
          enabled: 0, // always opens dropdown when input gets focus
        }
      });

      if (typeof tagify.on !== "undefined") {
        tagify.on('add', onAddTagMarket)
            .on('remove', onRemoveTagMarket)
      }

    }


    const setTagInputAgency = (wList) => {
      let inputAgency = document.querySelector('input[name=myTagsInputAgency]');
// init Tagify script on the above inputs
      tagify2 = new Tagify(inputAgency, {
        tagTextProp: 'value', // very important since a custom template is used with this property as text
        enforceWhitelist: true,
        duplicates: false,
        skipInvalid: true, // do not remporarily add invalid tags
        whitelist: wList,
        dropdown: {
          searchKeys: ["value"],
          maxItems: 10,
          closeOnSelect: false,
          position: "text",
          enabled: 0, // always opens dropdown when input gets focus
        },
      });
      tagify2.on('add', onAddTagAccount)
          .on('remove', onRemoveTagAccount)
    }

    function onAddTagAccount(e) {
      //console.log("onAddTag: ", e.detail);
      newPriceData.value.adultPriceAccount.push([[], [], [], [], []]);
      newPriceData.value.adultCostAccount.push([[], [], [], [], []]);

      newPriceData.value.childPriceAccount.push([[[]], [[]], [[]], [[]], [[]]]);
      newPriceData.value.childCostAccount.push([[[]], [[]], [[]], [[]], [[]]]);

      newPriceData.value.childMinADTAccount.push([[[]], [[]], [[]], [[]], [[]]]);

      newPriceData.value.childAgeStartAccount.push([[[]], [[]], [[]], [[]], [[]]]);
      newPriceData.value.childAgeFinishAccount.push([[[]], [[]], [[]], [[]], [[]]]);
      newPriceData.value.childAgeFromAccount.push([[[]], [[]], [[]], [[]], [[]]]);
      newPriceData.value.childAgeToAccount.push([[[]], [[]], [[]], [[]], [[]]]);

      newPriceData.value.minAccTypeAccount.push([[]]);
      newPriceData.value.minAccStatusAccount.push([[]]);
      newPriceData.value.minAccDayAccount.push([[]]);
      newPriceData.value.minPaymentAccount.push([[]]);

      newPriceData.value.cancelPolicyAccount.push([[]]);
      newPriceData.value.priceProfitTypeAccount.push([[]]);
      newPriceData.value.priceProfitAccount.push([[]]);
      newPriceData.value.priceOverCommAccount.push([[]]);

      newPriceData.value.priceCurAccount.push([[]]);
      newPriceData.value.costCurAccount.push([[]]);
      newPriceData.value.curRateAccount.push([[]]);
      newPriceData.value.curRateTypeAccount.push([[]]);


    }

// tag remvoed callback
    function onRemoveTagAccount(e) {
      //console.log("onRemoveTag:", e.detail, "tagify instance value:", tagify.value);
      //console.log("Index:", e.detail.index);

      //let delKey = Object.keys(myUnSelectedCapacity).find(key => myUnSelectedCapacity[key] === e.target.value)
      newPriceData.value.adultPriceAccount.splice(e.detail.index, 1);
      newPriceData.value.adultCostAccount.splice(e.detail.index, 1);

      newPriceData.value.childPriceAccount.splice(e.detail.index, 1);
      newPriceData.value.childCostAccount.splice(e.detail.index, 1);

      newPriceData.value.childMinADTAccount.splice(e.detail.index, 1);

      newPriceData.value.childAgeStartAccount.splice(e.detail.index, 1);
      newPriceData.value.childAgeFinishAccount.splice(e.detail.index, 1);
      newPriceData.value.childAgeFromAccount.splice(e.detail.index, 1);
      newPriceData.value.childAgeToAccount.splice(e.detail.index, 1);

      newPriceData.value.minAccTypeAccount.splice(e.detail.index, 1);
      newPriceData.value.minAccStatusAccount.splice(e.detail.index, 1);
      newPriceData.value.minAccDayAccount.splice(e.detail.index, 1);
      newPriceData.value.minPaymentAccount.splice(e.detail.index, 1);

      newPriceData.value.cancelPolicyAccount.splice(e.detail.index, 1);
      newPriceData.value.priceProfitTypeAccount.splice(e.detail.index, 1);
      newPriceData.value.priceProfitAccount.splice(e.detail.index, 1);
      newPriceData.value.priceOverCommAccount.splice(e.detail.index, 1);

      newPriceData.value.priceCurAccount.splice(e.detail.index, 1);
      newPriceData.value.costCurAccount.splice(e.detail.index, 1);
      newPriceData.value.curRateAccount.splice(e.detail.index, 1);
      newPriceData.value.curRateTypeAccount.splice(e.detail.index, 1);

    }

    function onAddTagMarket(e) {
      newPriceData.value.adultPriceMarket.push([[], [], [], [], []]);
      newPriceData.value.adultCostMarket.push([[], [], [], [], []]);

      newPriceData.value.childPriceMarket.push([[[]], [[]], [[]], [[]], [[]]]);
      newPriceData.value.childCostMarket.push([[[]], [[]], [[]], [[]], [[]]]);

      newPriceData.value.childMinADTMarket.push([[[]], [[]], [[]], [[]], [[]]]);

      newPriceData.value.childAgeStartMarket.push([[[]], [[]], [[]], [[]], [[]]]);
      newPriceData.value.childAgeFinishMarket.push([[[]], [[]], [[]], [[]], [[]]]);
      newPriceData.value.childAgeFromMarket.push([[[]], [[]], [[]], [[]], [[]]]);
      newPriceData.value.childAgeToMarket.push([[[]], [[]], [[]], [[]], [[]]]);

      newPriceData.value.minAccTypeMarket.push([[]]);
      newPriceData.value.minAccStatusMarket.push([[]]);
      newPriceData.value.minAccDayMarket.push([[]]);
      newPriceData.value.minPaymentMarket.push([[]]);

      newPriceData.value.cancelPolicyMarket.push([[]]);
      newPriceData.value.priceProfitTypeMarket.push([[]]);
      newPriceData.value.priceProfitMarket.push([[]]);
      newPriceData.value.priceOverCommMarket.push([[]]);

      newPriceData.value.priceCurMarket.push([[e.detail.data.cur]]);
      newPriceData.value.costCurMarket.push([[]]);
      newPriceData.value.curRateMarket.push([[]]);
      newPriceData.value.curRateTypeMarket.push([[]]);

    }

// tag remvoed callback
    function onRemoveTagMarket(e) {
      //console.log("onRemoveTag:", e.detail, "tagify instance value:", tagify.value);
      //console.log("Index:", e.detail.index);

      //let delKey = Object.keys(myUnSelectedCapacity).find(key => myUnSelectedCapacity[key] === e.target.value)
      newPriceData.value.adultPriceMarket.splice(e.detail.index, 1);
      newPriceData.value.adultCostMarket.splice(e.detail.index, 1);

      newPriceData.value.childPriceMarket.splice(e.detail.index, 1);
      newPriceData.value.childCostMarket.splice(e.detail.index, 1);

      newPriceData.value.childMinADTMarket.splice(e.detail.index, 1);

      newPriceData.value.childAgeStartMarket.splice(e.detail.index, 1);
      newPriceData.value.childAgeFinishMarket.splice(e.detail.index, 1);
      newPriceData.value.childAgeFromMarket.splice(e.detail.index, 1);
      newPriceData.value.childAgeToMarket.splice(e.detail.index, 1);

      newPriceData.value.minAccTypeMarket.splice(e.detail.index, 1);
      newPriceData.value.minAccStatusMarket.splice(e.detail.index, 1);
      newPriceData.value.minAccDayMarket.splice(e.detail.index, 1);
      newPriceData.value.minPaymentMarket.splice(e.detail.index, 1);

      newPriceData.value.cancelPolicyMarket.splice(e.detail.index, 1);
      newPriceData.value.priceProfitTypeMarket.splice(e.detail.index, 1);
      newPriceData.value.priceProfitMarket.splice(e.detail.index, 1);
      newPriceData.value.priceOverCommMarket.splice(e.detail.index, 1);

      newPriceData.value.priceCurMarket.splice(e.detail.index, 1);
      newPriceData.value.costCurMarket.splice(e.detail.index, 1);
      newPriceData.value.curRateMarket.splice(e.detail.index, 1);
      newPriceData.value.curRateTypeMarket.splice(e.detail.index, 1);

    }

    function onMarketChange(e) {
      // outputs a String
      //console.log(JSON.parse(e.target.value));

      if(e.target.value.length>0) {
        newPriceData.value.priceMarkets = JSON.parse(e.target.value);
      }else{
        newPriceData.value.priceMarkets = [];
      }

    }

    function onAccountChange(e) {
      // outputs a String
      //console.log(e.target.value.length);
      if(e.target.value.length>0) {
        newPriceData.value.priceAccount = JSON.parse(e.target.value);
      }else{
        newPriceData.value.priceAccount = [];
      }
    }

    //const tabList = computed(() => {
      const tabList = () => {

      newPriceData.value.adultPrice = newPriceData.value.adultPriceMarket.concat(newPriceData.value.adultPriceAccount);
      newPriceData.value.adultCost = newPriceData.value.adultCostMarket.concat(newPriceData.value.adultCostAccount);


      newPriceData.value.childPrice = newPriceData.value.childPriceMarket.concat(newPriceData.value.childPriceAccount);
      newPriceData.value.childCost = newPriceData.value.childCostMarket.concat(newPriceData.value.childCostAccount);

      newPriceData.value.childMinADT = newPriceData.value.childMinADTMarket.concat(newPriceData.value.childMinADTAccount);

      newPriceData.value.childAgeStart = newPriceData.value.childAgeStartMarket.concat(newPriceData.value.childAgeStartAccount);
      newPriceData.value.childAgeFinish = newPriceData.value.childAgeFinishMarket.concat(newPriceData.value.childAgeFinishAccount);
      newPriceData.value.childAgeFrom = newPriceData.value.childAgeFromMarket.concat(newPriceData.value.childAgeFromAccount);
      newPriceData.value.childAgeTo = newPriceData.value.childAgeToMarket.concat(newPriceData.value.childAgeToAccount);

      newPriceData.value.minAccType = newPriceData.value.minAccTypeMarket.concat(newPriceData.value.minAccTypeAccount);
      newPriceData.value.minAccStatus = newPriceData.value.minAccStatusMarket.concat(newPriceData.value.minAccStatusAccount);
      newPriceData.value.minAccDay = newPriceData.value.minAccDayMarket.concat(newPriceData.value.minAccDayAccount);
      newPriceData.value.minPayment = newPriceData.value.minPaymentMarket.concat(newPriceData.value.minPaymentAccount);

      newPriceData.value.cancelPolicy = newPriceData.value.cancelPolicyMarket.concat(newPriceData.value.cancelPolicyAccount);
      newPriceData.value.priceProfitType = newPriceData.value.priceProfitTypeMarket.concat(newPriceData.value.priceProfitTypeAccount);
      newPriceData.value.priceProfit = newPriceData.value.priceProfitMarket.concat(newPriceData.value.priceProfitAccount);
      newPriceData.value.priceOverComm = newPriceData.value.priceOverCommMarket.concat(newPriceData.value.priceOverCommAccount);

      newPriceData.value.priceCur = newPriceData.value.priceCurMarket.concat(newPriceData.value.priceCurAccount);
      newPriceData.value.costCur = newPriceData.value.costCurMarket.concat(newPriceData.value.costCurAccount);
      newPriceData.value.curRate = newPriceData.value.curRateMarket.concat(newPriceData.value.curRateAccount);
      newPriceData.value.curRateType = newPriceData.value.curRateTypeMarket.concat(newPriceData.value.curRateTypeAccount);


      //input.addEventListener("change", onChange, false);
      const tmpList = newPriceData.value.priceMarkets.concat(newPriceData.value.priceAccount);
      //console.log(tmpList);

      return tmpList;
    }

    const addEventToNumber = () => {
      var priceInput = document.querySelectorAll(".priceInput");
      priceInput.forEach(function (element) {
        console.log(element);
        element.addEventListener("keyup", commonf.inputDecimal, false);
      });
      return true;
    }
    const testFunc = () => {
      addEventToNumber();
      console.log("yhkhgjk");
      return true;
    };
    return {
      testFunc,
      addEventToNumber,
      myDetails2,
      roomPriceType,
      roomPricePlatform,
      weekDaysList,
      roomMinAccType,
      roomProfitType,
      setCapacity,
      curList,
      childAgeGroupCount,
      curExchangeType,
      myAgeGroupCount,
      myAgeGroupCountPlus,
      myAgeGroupCountMinus,
      onMarketChange,
      onAccountChange,
      myRoomsList,
      myConceptList,
      myTagsMarkets,
      myTagsAgency,
      tabList,
      newPriceData,
      roomActive,
      roomSale,
      validationSchema,
      submit,
      submitButtonRef,
      newRoomModalRef,
      MasterConfig,
    };
  },
});
